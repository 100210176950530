/* src/GangstaParadiseSection.css */
.gangsta-paradise-section {
  background-color: black; /* Light background color */
  /* height: calc(100vh - 60px); Full viewport height minus header height */
  padding: 20px;
}

.gangsta-card {
  background-color: #000; /* Set the background color to black */
  color: #fff; /* Set text color to white */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for elevation */
}

.gangsta-content {
  background-color: black;
  border: 0.1rem solid grey;
  color: white;
}

/* Elevate card on hover */
.gangsta-card:hover {
  transform: translateY(-10px); /* Move the card up */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Add shadow effect */
}

.contact-info {
  margin-top: 20px;
}

.map-container {
  margin-bottom: 20px;
}

.phone-number {
  font-size: 18px;
}

.phone-number a {
  color: #fff; /* Set link color to white */
  text-decoration: none;
}

.phone-number a:hover {
  text-decoration: underline;
}
